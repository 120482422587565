<template>
  <div>
    <NewCase ref="newCaseForm"></NewCase>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
const NewCase = defineAsyncComponent(() =>
  import("@/components/cases/NewCase")
);
export default {
  components: {
    NewCase,
  },
  mounted() {},
};
</script>
